import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {useSearchParams, useNavigate, useLocation, Link} from 'react-router-dom';
import parse from 'html-react-parser';

import BreadCrumb from "components/breadCrumb";
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import TABLE from 'components/app/school/SmallerComponents/table_html/table';
import TR from 'components/app/school/SmallerComponents/table_html/tr';
import TD from 'components/app/school/SmallerComponents/table_html/td';
import TH from 'components/app/school/SmallerComponents/table_html/th';

import Loader from "components/app/school/SmallerComponents/loader";
import BlankLoader from "components/blankLoader";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import { HeadComponent } from "components/head";
import { BACKEND_URL } from 'actions/url';
import { appServices, appServices5 } from 'api/schoolApp/services';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { filterObjectByKey} from 'components/app/school/actions/array-utils/Filter';
import { sortByName } from 'components/app/school/actions/array-utils/Sort';
import { numberDigit } from 'components/app/school/actions/numberDigit';
import { nth } from 'components/app/school/SmallerComponents/action/nth';
import { updateArray } from '../actions/array-utils/Update';

const ResultStudent = ({school_id, school, user_id}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialTraitState = useMemo(() => ({id:0, uid:'', commendation:"", manners:"", attentiveness:'', honesty:'', neatness:'', politeness:'', punctuality:''}), []);
    const initialTraitPsychState = useMemo(() => ({id:0, uid:'', handwriting:'', sports:'', drawing_and_painting:'', art_and_crafts:''}), []);
    const initialMalletState = useMemo(() => ({session:'', classes:'', school_branch:'', pre:false, curr_session:'', active_session:false, reload:false, student:''}), []);
    const initialStudentResult = useMemo(() => ({id:'', scores:[], attendance:[], trait_score:{id:0}, is_uploaded:false}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);
    const [malletState, setMalletState] = useState(initialMalletState);
    const [studentResult, setStudentResult] = useState(initialStudentResult);
    const [studentResultStatus, setStudentResultStatus] = useState({});

    const [allScoresEtable, setAllScoresEtable] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allSessionData, setAllSessionData] = useState([]);
    const [allClassesData, setAllClassesData] = useState([]);
    const [allStudentData, setAllStudentData] = useState([]);

    const [navigate, setNavigate] = useState('');

    const [preloading, setPreloading] = useState(true);
    const [searchParamLoading, setSearchParamLoading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [isCheckResult, setIsCheckResult] = useState(false);
    const [malletTableLoading, setMalletTableLoading] = useState(true);
    const [isTrackLocationChanges, setIsTrackLocationChanges] = useState(false);
    const [isBeginPromotion, setIsBeginPromotion] = useState(false);

    const [autoGenerateRemark, setAutogenerateRemark] = useState(-1);

    const [searchParam] = useSearchParams()
    const search = useLocation()

    const nav = useNavigate()

    const fetchAllSchoolSession = (sid, ssid) => {
        if(sid){
            setError({data:[], title:''});
            appServices.getCurrentSession(sid).then(sess_res => {
                if(sess_res.data.id){
                    setMalletState((prev) => typeof prev === "object" && {...prev, session:sess_res.data.id, curr_session:sess_res.data.id, pre:true, vac_date:sess_res.data.vac_date});
                }
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", handleClick: () => setReload(true)});
            })
            appServices.getAllSession(sid).then(res => {
                setAllSessionData(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Session Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllScores = (sid) => {
        if(sid){
            setError({data:[], title:""});
            appServices5.getAllSchoolScores(sid).then(res => {
                setAllScoresEtable(addItemToArray(res.data))
            }).catch(e => {
                setError({title:'School Scores Data Fetch Error', data:['Internal Server Error'], click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    const fetchAllSchoolBranch = (sid) => {
        if(sid){
        setError({data:[], title:''});
        appServices.getAllSchoolBranch(sid).then(res => {
            setAllSchoolBranchData(res.data);
        }).catch(e => {
            setAllSchoolBranchData([]);
            setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchAllClasses = (sid) => {
        if(sid){
        setError({data:[], title:''});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClassesData([...res.data]);
            setPreloading(false);
        }).catch(e => {
            setAllClassesData([]);
            setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
        })
        }
    }
    const fetchStudentResult = (ssid, stuid, cid, init) => {
        setError({data:[], title:''});
        setStudentResultStatus({});
        setStudentResult(init);
        setLoading(true);
        setSpinLoading(true);
        setIsBeginPromotion(false);
        appServices.getStudentResult(ssid, stuid, cid).then(res => {
            appServices.getSchoolStudentAttendanceAll(ssid, stuid).then(res2 => {
                setStudentResult({...init, ...res.data, attendance:res2.data});
                setLoading(false);
                setSpinLoading(false);
            }).catch(e => {
                setLoading(false);
                setSpinLoading(false);
                setError({title:'Student Result Data Fetch Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
            })
            if(!res.data.is_uploaded){
                appServices.getStudentResultStatus(res.data.uid, ssid).then(res3 => {
                    const arr = addItemToArray(res3.data.arr, {is_clicked:false});
                    setStudentResultStatus({...res3.data, arr:updateArray(arr, 0, {...arr[0], is_clicked:true})});
                    setIsBeginPromotion(true);
                }).catch(e => {
                    console.log(e.response);
                })
            }
            
        }).catch(e => {
            setIsCheckResult(false);
            setSpinLoading(false);
            setLoading(false);
            setError({title:'Student Result Data Fetch Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
        })
    }
    const fetchAllStudent = (sid, sbid, cid) => {
        if(sid && sbid && cid){
            setError({data:[], title:''});
            appServices.getAllStudent_MinorAdmin(sid, sbid, '', cid, 1).then(res => {
                setMalletTableLoading(false);
                const arr = addItemToArray(res.data.sort((a, b) => {
                    const c = a.user_last_name ? `${a.user_last_name} ${a.user_first_name}` : `${a.last_name} ${a.first_name}`;
                    const d = b.user_last_name ? `${b.user_last_name} ${b.user_first_name}` : `${b.last_name} ${b.first_name}`;
                    if(c.toLowerCase() > d.toLowerCase()){
                        return 1;
                    }
                    if(c.toLowerCase() < d.toLowerCase()){
                        return -1;
                    }
                    return 0;
                }))
                setAllStudentData(arr);
            }).catch(e => {
                setAllStudentData([]);
                setMalletTableLoading(false);
                setError({data:["Internal Server Error"], title:"Student Data Fetch Error"});
            })
            return;
        }
        setMalletTableLoading(false);
        setAllStudentData([]);
    }

    const handleMalletChange = (e) => {
        if(!e.target.value && e.target.name !== "student"){
            setMalletState({...malletState, [e.target.name]:e.target.value, student:''});
            setAllStudentData([]);
            setMalletTableLoading(false);
            setIsCheckResult(false);
            setStudentResult(initialStudentResult);
            setNavigate(`?s=&sc&scl=&sst=`)
            return;
        }
        if(e.target.name === "classes" || e.target.name === "school_branch"){
            setMalletState({...malletState, [e.target.name]:e.target.value, student:''});
            setIsCheckResult(false);
            setStudentResult(initialStudentResult);
            setMalletTableLoading(true);
            setNavigate(e.target.name === "classes" ? `?s=${malletState.session}&sc=${malletState.school_branch}&scl=${e.target.value}&sst=${malletState.student}` : `?s=${malletState.session}&sc=${e.target.value}&scl=${malletState.classes}&sst=${malletState.student}`)
            return;
        }
        if(e.target.name === "session"){
            setNavigate(`?s=${e.target.value}&sc=${malletState.school_branch}&scl=${malletState.classes}&sst=${malletState.student}`)
        }
        if(e.target.name === "student"){
            setNavigate(`?s=${malletState.session}&sc=${malletState.school_branch}&scl=${malletState.classes}&sst=${e.target.value}`)
        }
        // if((e.target.name === "classes" && malletSt && e.target.value) || (e.target.name === "school_branch" && malletState.subject && e.target.value)){
        //     setMalletTableLoading(true);
        // }
        setIsCheckResult(false);
        setStudentResult(initialStudentResult);
        setMalletState({...malletState, [e.target.name]:e.target.value});
    }
    const handleRemarkChange = (e) => {
        setStudentResult({...studentResult, [e.target.name]:e.target.value})
    }
    const handleResultCheckClick = () => {
        if(loadData){
            setError(initialErrorState);
            setDataTableLoading(true);
            setSearchParamLoading(true);
            setIsCheckResult(true);
        }
    }
    const handleResultUpload = (e) => {
        e.preventDefault();
        setWarning({id:0, title:'', text:''});
        setTimeout(() => {
            setWarning({id:5, click_text:`Upload`, 
             title:"Warning", text:"Data Cannot Be Altered Once Uploaded, Are You Sure?",
             handleClick: () =>{
                setError(initialErrorState);
                setSuccess(initialSuccessState);
                setLoading(true);
                const attend_present = studentResult.attendance.filter((it) => it.is_present === true).length;
                const attend_absent = studentResult.attendance.filter((it) => it.is_absent === true).length;
                const filterClicked = isBeginPromotion ? studentResultStatus.arr.filter((it) => it.is_clicked) : [];
                const data = !isBeginPromotion ? {is_uploaded:true, principal_remark:studentResult.principal_remark, tot_attend_present:attend_present, tot_attend_absent:attend_absent, updated_by:user_id, created_by:user_id} : {is_uploaded:true, is_promoted:studentResultStatus.is_promoted, is_alumni:studentResultStatus.is_alumni, prom_class:filterClicked.length > 0 ? filterClicked[0].id : null, principal_remark:studentResult.principal_remark, tot_attend_present:attend_present, tot_attend_absent:attend_absent, updated_by:user_id, created_by:user_id};
                appServices.uploadStudentResult(studentResult.uid, data).then(res => {
                    setLoading(false);
                    setDataTableLoading(true);
                    setSuccess({title:'Success', text:'Student Result Uploaded Successfully'});
                    setWarning({id:0, title:'', text:''});
                }).catch(e => {
                    setLoading(false);
                    setWarning({id:0, title:'', text:''});
                    setError({title:'Data Upload Error', data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong']})
                })
            }
            })
        })
        
    }
    const handleArrowClick = (num=-1) => {
        const stud_item = allStudentData.filter((it) => it.num === num)[0];
        setMalletState({...malletState, student:stud_item.uid});
        setNavigate(`?s=${malletState.session}&sc=${malletState.school_branch}&scl=${malletState.classes}&sst=${stud_item.uid}`)
        setIsTrackLocationChanges(true);
        setAutogenerateRemark(-1);
    }

    useEffect(() => {
        if(reload){
            setReload(false);
            fetchAllSchoolSession(school_id);
            fetchAllClasses(school_id);  
            fetchAllSchoolBranch(school_id);
            fetchAllScores(school_id);
        }
    }, [reload, school_id])

    useEffect(() => {
        if(searchParamLoading && malletTableLoading && searchParam.get('s') && searchParam.get('sc') && searchParam.get('scl') && searchParam.get('sst')){
            setMalletState((prev) => typeof prev === "object" ? {...prev, classes:searchParam.get('scl'), school_branch:searchParam.get('sc'), session:searchParam.get('s'), student:searchParam.get('sst')} : prev);
            fetchAllStudent(school_id, searchParam.get('sc'), searchParam.get('scl'));
            setSearchParamLoading(false);
            setDataTableLoading(true);
            setStudentResult(initialStudentResult);
            setIsCheckResult(false);
            return;
        }
        if(dataTableLoading && isCheckResult){
            setDataTableLoading(false);
            fetchStudentResult(searchParam.get('s'), searchParam.get('sst'), searchParam.get('scl'), initialStudentResult)
            return;
        }
        // if(searchParamLoading && searchParam.get('s') && searchParam.get('sc') && searchParam.get('scl') && searchParam.get('sst')){
        //     setMalletState({...malletState, classes:searchParam.get('scl'), school_branch:searchParam.get('sc'), session:searchParam.get('s'), student:searchParam.get('sst')});
        //     setSearchParamLoading(false);
        //     setIsCheckResult(false);
        //     return;
        // }
        if(malletTableLoading){
            fetchAllStudent(school_id, malletState.school_branch, malletState.classes);
            setStudentResult(initialStudentResult);
            return;
        }
        if(autoGenerateRemark > -1){
            setLoading(true);
            appServices.initRemark(school_id, autoGenerateRemark).then(res => {
                setStudentResult((prev) => prev.id ? {...prev, principal_remark:res.data.remark} : prev);
                setAutogenerateRemark(-1);
                setLoading(false);
            }).catch(e => {
                setError({title:'Autogeneration Error', data:['Remark Autogeneration Error']});
                setAutogenerateRemark(-1);
                setLoading(false);
            })
            return;
        }
    }, [malletState, malletTableLoading, autoGenerateRemark, dataTableLoading, isCheckResult, school_id, search, searchParam, searchParamLoading, initialMalletState, initialStudentResult]);
    useEffect(() => {
        if(searchParam.get('s') && searchParam.get('sc') && searchParam.get('scl') && searchParam.get('sst')){
            setLoadData(true);
        }
        if(!searchParam.get('s') && !searchParam.get('sc') && !searchParam.get('scl') && !searchParam.get('sst')){
            setLoadData(false);
            setIsCheckResult(false);
            setStudentResult(initialStudentResult);
        }
        if(!searchParam.get('s') && malletState.session){
            setNavigate(`?s=${malletState.session}&sc&scl=&sst=`);
        }
        
    }, [searchParam, malletState, initialStudentResult])
    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav]);
    useEffect(() => {
        setIsTrackLocationChanges(true);
    }, [search])
    useEffect(() => {
        if(isTrackLocationChanges && !malletTableLoading && searchParam.get('s') && searchParam.get('sc') && searchParam.get('scl') && searchParam.get('sst')){     
            setDataTableLoading(true);
            setStudentResult(initialStudentResult);
            setIsTrackLocationChanges(false);
            return;
        }
        if(isTrackLocationChanges){
            setDataTableLoading(false);
            setStudentResult(initialStudentResult);
            setIsTrackLocationChanges(false);
            return;
        }
    }, [searchParam, search, malletState, malletTableLoading, isCheckResult, isTrackLocationChanges, initialStudentResult])
    
    console.log(studentResult, studentResultStatus);
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertSessionOption = allSessionData.map((item) => {
        return(
            <option value={item.id} key={item.id}>{`${item.session} - ${item.term_name} term`}</option>
        )
    })
    const insertClassesOption = allClassesData.map((item, i) => {
        return(
            <option value={item.id} key={i}>{item.name}</option>
        )
    })
    const insertSchoolBranchOption = allSchoolBranchData.map((item, i) => {
        return(
            <option value={item.id} key={i}>{item.name}</option>
        )
    })
    const insertSchoolStudentOption = allStudentData.map((item, i) => {
        const full_name = item.user_last_name ? `${item.user_last_name} ${item.user_first_name}` : `${item.last_name} ${item.first_name}`;
        return(
            <option value={item.uid} key={i}>{full_name}</option>
        )
    })
    
    const insertScoresEntryData = () => {
        const all_scores = allScoresEtable;
        const stud_name_width = (100 - ((all_scores.length) * 5) + 10);
        const split_str = (str="", search="") => {
            const spl = str.split(search)
            if(spl.length > 0){
                return spl.map((item) => (`${item} `));
            }
            return str;
        }
        const attend_present = studentResult.attendance.filter((it) => it.is_present === true).length
        const attend_absent = studentResult.attendance.filter((it) => it.is_absent === true).length
        const trait_score = studentResult.trait_score.id ? filterObjectByKey(studentResult.trait_score, initialTraitState) : initialTraitState;
        const trait_psych_score = studentResult.trait_score.id ? filterObjectByKey(studentResult.trait_score, initialTraitPsychState) : initialTraitPsychState;
        const sess_item = allSessionData.filter((it) => it.id === parseInt(searchParam.get('s')))[0];
        const full_name = studentResult.user_last_name ? `${studentResult.user_last_name} ${studentResult.user_first_name}` : `${studentResult.last_name} ${studentResult.first_name}`;
        return (
            <>
            <div className='flex-100 align-center padd-4px' style={{marginBottom:'4px'}}><span className='app-text-color fw-700 font-med text-capitalize'> {full_name} Result Collation</span></div>
            {studentResult.is_uploaded && (
                <div className='flex-100 align-center'><span className='green fw-600 font-very-small'>**Data Uploaded Successfully</span></div>
            )}
            <div className="flex-100 padd-4px" style={{marginBottom:'4px'}}>
                <div style={{position:'absolute', right:'20px'}}>
                    <img className='br-circle img' style={{width:'70px', height:'80px'}} src={`${BACKEND_URL}${studentResult.user_profile_pic ? studentResult.user_profile_pic : studentResult.profile_pic}`} alt={'student'} />
                </div>
                <div className=' disp-flex wrap'>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>ADM NO:. </label> <span className='app-text-color fw-600 font-very-small'>{numberDigit(studentResult.adm_no, 4)}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Class: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.class_name}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Date Of Birth: </label><span className='app-text-color fw-500 font-very-small'>{studentResult.dob}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Session Interval: </label> <span className='app-text-color fw-500 font-very-small'>{sess_item.session}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Term: </label><span className='app-text-color fw-500 font-very-small text-capitalize'>{sess_item.term_name}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>No. In Class: </label><span className='app-text-color fw-500 font-very-small'>{sess_item.tot_in_class}</span>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Attendance Present: {attend_present}</label>
                    </div>
                    <div className='flex-30-m align-center-m'>
                        <label className='app-text-color fw-600 font-slightly-small'>Attendance Absent: {attend_absent}</label>
                    </div>
                </div>
            </div>
            {studentResult.trait_score.id && (
                <div className='flex-100 align-right padd-4px'>
                    <label className={`font-very-small fw-600 app-text-color `}>Final Position: </label> <span className={`font-med fw-700 app-text-color align-center`}>{studentResult.final_pos}{parse(nth(studentResult.final_pos, true))}</span>
                </div>
            )}
            {/* {isStaffUploaded && <div className='flex-100 padd-4px'><span className='disp-block align-center font-small fw-600 green'><i>**Data Has Been Uploaded Successfully</i></span></div>} */}
            <div className='flex-100' style={{width:'0'}}>
                <TABLE>
                    <thead>
                        <TR>
                            <TH style={{padding:'5px', width:`${stud_name_width}%`}}><span className="font-slightly-small">Subject</span></TH>
                            {all_scores.map((item_j, j) => (
                                <Fragment key={j}>
                                    <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">{item_j.short_name}</span></TH>
                                </Fragment>
                            ))}
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Total</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Grade</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Remark</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Pos</span></TH>
                            <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">Teacher</span></TH>
                            {/* <TH style={{padding:'5px', width:'5%'}}><span className="font-slightly-small">GRD</span></TH> */}
                        </TR>
                    </thead>
                    <tbody>
                        {sortByName(studentResult.scores, 'subject_name', true).map((item, i) => {
                            // const full_name = item.subject_offering.user_last_name ? `${item.subject_offering.user_last_name} ${item.subject_offering.user_first_name}` : `${item.subject_offering.last_name} ${item.subject_offering.first_name}`;
                            const exam_score = item.scores
                            const scores = item.subject_offering.scores_temp;
                            const teacher_name = item.scores.length > 0 ? `${item.scores[0].created_by_last_name} ${item.scores[0].created_by_first_name}`: (item.subject_offering.scores_temp.length > 0 ? `${item.subject_offering.scores_temp[0].created_by_last_name} ${item.subject_offering.scores_temp[0].created_by_first_name}` : '');
                            const insertScores = () => {
                                return allScoresEtable.filter((it) => it.short_name.toLowerCase() !== "exam").map((item_k, j) => {
                                    let item_jj = scores.filter((it) => it.score_type === item_k.id || it.short_name === item_k.short_name)
                                    let item_j = item_jj.length > 0 ? item_jj[0] : {id:0, is_absent:false, mark:''}
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{item_j.is_absent ? 'ABS' : parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            const insertExamScore = () => {
                                return exam_score.map((item_j, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD>
                                                <span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>{parseFloat(item_j.mark)}</span>
                                            </TD>
                                        </Fragment>
                                    )
                                })
                            }
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD align='center'><span className="font-slightly-small fw-600 app-text-color text-capitalize">{item.subject_name}</span></TD>
                                        {insertScores()}
                                        {item.is_present ? insertExamScore() : (<TD><span className={`disp-block font-slightly-small fw-500 app-text-color align-center`}>ABS</span></TD>)}
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.tot_mark}</span>}</TD>
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.curr_grade}</span>}</TD>
                                        <TD>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{item.curr_grade_type}</span>}</TD>
                                        <TD>{<span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{item.pos}</span>}</TD>
                                        <TD>{<span className={`disp-block font-super-small fw-600 app-text-color align-center`}>{teacher_name}</span>}</TD>
                                        {/* <TD>{<span className={`disp-block font-slightly-small fw-700 app-text-color align-center`}>{item.curr_grade}</span>}</TD>           */}
                                    </TR>    
                                </Fragment>
                            )

                        })}
                        {studentResult.trait_score.id && (
                            <>
                            <TR>
                                {allScoresEtable.map((it, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <TD></TD>
                                        </Fragment>
                                    )
                                })}
                                <TD colspan={3}><span className={`disp-block font-very-small fw-600 app-text-color align-center`}>{studentResult.fin_mark}</span></TD>
                            </TR>
                            <TR>
                                <TD colspan={7}>
                                    <div className='disp-block align-center'>
                                        <label className={`font-very-small fw-600 app-text-color `}>Final Average: </label> <span className={`font-slightly-small fw-700 app-text-color align-center`}>{studentResult.fin_avr}</span>
                                    </div>
                                </TD>
                            </TR>
                            
                            </>
                        )}
                    </tbody>
                </TABLE>
            </div>
            {!studentResult.trait_score.id && (
                <div className='flex-100'>
                    <span className='disp-flex font-slightly-small red'><b>Error:</b> To begin the result collation for this student, the form teacher must input their scores on their end</span>
                </div>
            )}
            <div className='disp-flex wrap'>
                <div className='flex-50 padd-4px'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">Effective Traits</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rating</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                <span className='font-slightly-small fw-600'>{trait_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                <div className='flex-50 padd-4px'>
                    <TABLE>
                        <thead>
                            <TR>
                                <TH style={{padding:'5px', width:`90%`}}><span className="font-slightly-small">PSYCHOMOTOR</span></TH>
                                <TH style={{padding:'5px'}}><span className="font-slightly-small">Rating</span></TH>
                            </TR>
                        </thead>
                        <tbody>
                            {Object.keys(trait_psych_score).filter((it) => it !== "id" && it !== "uid").map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD><span className="font-slightly-small fw-600 app-text-color text-capitalize">{split_str(item, '_')}</span></TD>
                                            <TD>
                                                <span className='font-slightly-small fw-600'>{trait_psych_score[item]}</span>
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    </TABLE>
                </div>
                {studentResult.trait_score.id && (
                    
                    <div className='flex-100'>
                    <form onSubmit={e => handleResultUpload(e)}>
                        {!studentResult.is_uploaded && <div className='disp-block align-right padd-4px' style={{marginBottom:'4px'}}><button type="button" className="font-very-small btn app-bg-color padd-4px app-bg-text-color hover-opacity br" onClick={() => setAutogenerateRemark(studentResult.fin_avr)} ><i className="fas fa-recycle"></i> Autogenerate Remark</button></div>}
                        <TABLE>
                            <tbody>
                                {studentResult.is_init_promote ? (
                                    <>
                                        <TR>
                                            <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Status</span></TD>
                                            <TD style={{width:'70%'}}>{studentResult.is_promote ? <span className='font-slightly-small green fw-600'>PROMOTION</span> : <span className='font-slightly-small red fw-600'>DEMOTION</span>} <br /> {studentResult.is_promote_to_next_class && (
                                                <div className='disp-block'>
                                                    <span className='disp-block font-very-small app-text-color align-center fw-600 '>Class Promoted: {studentResult.prom_class_name}</span>
                                                </div>
                                            )} </TD>
                                        </TR>
                                    </>
                                ) : (
                                    <>
                                        {isBeginPromotion && (
                                            <TR>
                                                <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Status</span></TD>
                                                <TD style={{width:'70%'}}>{studentResultStatus.is_promoted ? <span className='font-slightly-small green fw-600'>PROMOTION</span> : <span className='font-slightly-small red fw-600'>DEMOTION</span>} <br /> {studentResultStatus.is_promoted && (
                                                    <div className='disp-block'>
                                                        <span className='disp-block font-super-small app-text-sec-color align-center fw-700 '>Class to Promote</span>
                                                        <div className='' style={{marginBottom:'5px', display:'inline-flex'}}>
                                                            {studentResultStatus.arr.map((item, i, arr) => {
                                                                return(
                                                                    <Fragment key={i}>
                                                                        <div className='disp-flex jc-sb' onClick={() => setStudentResultStatus({...studentResultStatus, arr: updateArray(addItemToArray(arr, {is_clicked:false}), item.num, {...item, is_clicked:!item.is_clicked})})} style={{marginRight:'10px'}}><div className='disp-block' style={{paddingRight:'5px'}}><span className='font-very-small app-text-color'>{item.name}</span></div> {!item.is_clicked  ? (<div style={{display:'inline-flex'}}><i className='fas fa-check app-bg-text-color font-small'></i> {i > 0 && <i className='fas fa-check app-bg-text-color font-small'></i>}</div>) : (<div style={{display:'inline-flex'}}><i className='fas fa-check green font-small'></i> {i > 0 && <i className='fas fa-check green font-small'></i>}</div>)} </div>
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )} </TD>
                                            </TR>
                                        )}
                                    </>
                                )}
                                
                                
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Management's Remark</span></TD>
                                    <TD style={{width:'70%'}}><textarea type="text" name="principal_remark" placeholder="Enter Student's Remark" disabled={studentResult.is_uploaded} required={!studentResult.is_uploaded} className="form-control-theme font-slightly-small " onChange={e => handleRemarkChange(e)} value={studentResult.principal_remark ? studentResult.principal_remark : ''} /></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Form Teacher's Name</span></TD>
                                    <TD style={{width:'70%'}}><span className='font-very-small'>{studentResult.trait_score.created_by_last_name} {studentResult.trait_score.created_by_first_name}</span></TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Form Teacher's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.trait_score.remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Voskool's Remark</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.voskool_remark}</TD>
                                </TR>
                                <TR>
                                    <TD style={{width:'30%', textTransform:'uppercase'}}><span className='fw-600 font-very-small'>Vacation Date</span></TD>
                                    <TD style={{width:'70%'}}>{studentResult.vac_date}</TD>
                                </TR>
                            </tbody>
                        </TABLE>
                        {!studentResult.is_uploaded ? (
                            <div className='disp-block align-center'><button type="submit" className='app-bg-color mybtn app-bg-text-color' >Upload <i className='fas fa-paper-plane'></i></button></div>
                        ) : (
                            <div className='align-center'><span className='green fw-600 font-very-small'>**Data Uploaded Successfully</span></div>
                        )}
                    </form>
                    </div>
                    
                    )}
            </div>
            </>
        )
    }
    const check_sess_vac_date = parseInt(searchParam.get('s')) === parseInt(malletState.curr_session) ? !malletState.vac_date ? {vac_date:malletState.vac_date} : {vac_date:'error'} : {vac_date:'error'};
    const next = searchParam.get('sst') ? (allStudentData.length > 0 ? allStudentData.filter((it) => it.uid === searchParam.get('sst'))[0].num + 1 : 0) : 0;
    const prev = searchParam.get('sst') ? (allStudentData.length > 0 ? allStudentData.filter((it) => it.uid === searchParam.get('sst'))[0].num - 1 : -1) : -1;
    return(
        <>
            <HeadComponent title='Manage Student Result' />
            <BreadCrumb arr={['Voskool', school.title, 'result_student']} />
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                    {!preloading && (
                        <>
                        {!check_sess_vac_date.vac_date && (
                            <>
                            <Notification timer={1000 * 60 * 60 * 2} >Term vacation date not found click <Link to={`/${school.title}/session`} >here</Link> to update it or else the attendance might run into errors</Notification>
                            </>
                        )}
                        </>
                    )}
                </NotificationWrapper>   

                <Grid layout='lauto'>
                    <GridContent>
                        {loading && <Loader />}
                        {preloading ? (<ul>
                            <BlankLoader num={15} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0', width:'100%'}} text_style={{padding: '10px'}} />
                        </ul>) : 
                        (
                        <>
                        <Section>
                            <Mallet>
                                <div className="flex-50-line"><label className='font-super-small'>Select Session/Term: <br />
                                    <select name="session" onChange={e => handleMalletChange(e)} value={searchParam.get('s')} className='font-slightly-small text-capitalize form-control-theme'>
                                        <option value={''}>Select Session/term</option>
                                        {insertSessionOption}
                                    </select>
                                    </label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>Select School Branch: <br />
                                    <select name="school_branch" onChange={e => handleMalletChange(e)} value={searchParam.get('sc')} className='font-slightly-small text-capitalize form-control-theme'>
                                        <option value={''}>Select School Branch</option>
                                        {insertSchoolBranchOption}
                                    </select>
                                    </label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>Select Class: <br />
                                    <select name="classes" onChange={e => handleMalletChange(e)} value={searchParam.get('scl')} className='font-slightly-small text-capitalize form-control-theme'>
                                        <option value={''}>Select Class</option>
                                        {insertClassesOption}
                                    </select>
                                    </label>
                                </div>
                                <div className="flex-50-line"><label className='font-super-small'>Select Student: <br />
                                    <select name="student" onChange={e => handleMalletChange(e)} value={searchParam.get('sst')} className='font-slightly-small text-capitalize form-control-theme'>
                                        <option value={''}>{malletTableLoading ? 'Loading...' :  `Select Student${allStudentData.length > 0 ? '...' : ''}`}</option>
                                        {insertSchoolStudentOption}
                                    </select>
                                    </label>
                                </div>
                                {!isCheckResult ? (
                                    <div className='flex-100 align-center padd-4px'>
                                        <button type="button" disabled={!loadData} onClick={() => handleResultCheckClick()} className='mybtn app-bg-color  app-bg-text-color' style={loadData ? {cursor:'pointer'} : {cursor:'not-allowed', opacity:.5}}><span className='font-slightly-small'>Check Result</span> <i className='font-small fas fa-street-view'></i></button>
                                    </div>
                                ) : <>{!studentResult.id && (
                                    <>
                                    {!spinLoading && (
                                        <div className='flex-100 align-center padd-4px'>
                                            <span className='font-slightly-small fw-600 red'>No Record Found</span>
                                        </div>
                                    )}
                                    </>
                                    
                                )}</>}    
                            </Mallet>
                        </Section>
                        </>
                        )}
                    </GridContent>
                    {isCheckResult && (
                        <>
                        {!spinLoading && (
                            <GridContent>
                                {loading && <Loader />}
                                <Section>
                                <div className='disp-flex wrap'>
                                    {studentResult.id  && (
                                        <>
                                        {prev > -1 && (
                                            <div className="flex-5-line">
                                                <div className='disp-flex jc-c v-align-middle'>
                                                    <button type="button" className='btn app-bg-color app-bg-text-color br-circle padd-4px hover-opacity font-med' onClick={() => handleArrowClick(prev)} title="Previous" style={{padding:'5px'}}><span className='fas fa-arrow-left'></span></button>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                    )} 
                                    <div className='flex-90-line'>
                                        <div className='disp-flex wrap'>
                                            {studentResult.id ? insertScoresEntryData() : (<> {!spinLoading && <div className='flex-90-line align-center'><span className='red fw-700 font-small '>No Record Found</span></div>}</>)}
                                        </div>
                                    </div>
                                    {studentResult.id && (
                                        <>
                                        {allStudentData.length > next  && (
                                            <div className="flex-5-line">
                                                <div className='disp-flex v-align-middle jc-c'>
                                                <button type="button" className='btn app-bg-color app-bg-text-color br-circle padd-4px hover-opacity font-med' onClick={() => handleArrowClick(next)} title="Next" style={{padding:'5px'}}><span className='fas fa-arrow-right'></span></button>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                    )} 
                                </div>
                                </Section>
                            </GridContent>
                        )}
                        </>
                    )}
                </Grid>
            </ComponentWrapper>
        </>
    )
}

export default ResultStudent;